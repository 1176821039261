<template>
    <div id="users">
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <el-table v-loading="loading" :data="tableData" border style="width: 100%;" :span-method="objectSpanMethod"
                      @cell-mouse-enter="handleCellMouseEnter"
                      @cell-mouse-leave="handleCellMouseLeave"
                      :row-class-name="tableRowClassName">
                <el-table-column label="UkeyId" prop="ukeyId" width="152"></el-table-column>
                <el-table-column width="100" show-overflow-tooltip label="锁定状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.locked===true" type="danger">锁定</el-tag>
                        <el-tag v-if="scope.row.locked===false" type="success">正常</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="Agent端末Id" width="200" prop="agentId"></el-table-column>
                <el-table-column label="License生成日" width="200" prop="licenseCreateTime"></el-table-column>
                <el-table-column label="赋权时间" width="200" prop="grantTime"></el-table-column>
                <el-table-column label="软件名" width="200" prop="softwareName"></el-table-column>
                <el-table-column label="使用期限" width="120" prop="endTime"></el-table-column>
                <el-table-column label="安装状态" width="80">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" v-if="scope.row.installed===false" plain
                                   @click="getSoftwareById(scope.row)">安装
                        </el-button>
                        <el-tag v-if="scope.row.installed===true" type="success">已安装</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button size="small" type="warning" plain @click="history(scope.row.ukeyId)">履历</el-button>
                        <el-button size="small" type="danger" plain v-if="scope.row.locked===false  "
                                   @click="lock(scope.row.ukeyId)">锁定
                        </el-button>
                        <el-button size="small" type="primary" plain v-if="scope.row.locked===true"
                                   @click="unlock(scope.row.ukeyId)">解锁
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 添加对话框 -->
            <el-dialog :title="fqDialoTitle" class="class_dialog_hospital" @close="recordClose" :visible.sync="addDialogVisible" width="40%">
                <el-card class="box-card">
                    <el-table v-loading="loading" :data="historyTableData" border style="width: 100%;"
                              :span-method="objectSpanMethods"
                              @cell-mouse-enter="handleCellMouseEnters"
                              @cell-mouse-leave="handleCellMouseLeaves"
                              :row-class-name="tableRowClassNames">
                        <el-table-column label="赋权时间" width="200" prop="grantTime"></el-table-column>
                        <el-table-column label="软件名" width="200" prop="softwareName"></el-table-column>
                        <el-table-column label="使用期限" width="120" prop="endTime"></el-table-column>
                    </el-table>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="recordClose">取 消</el-button>
                </span>
            </el-dialog>

            <!-- 安装对话框 -->
            <el-dialog title="安装" class="class_dialog_hospital" :visible.sync="installDialogVisible" width="40%"
                       @close="closeDialogInstall">
                <el-card class="box-card">
                    <p>软件名: {{addForm.softwareName}}</p>
                    <el-table :data="softwareVersionList" ref="multipleTable"  @row-click="rowClickHandle" border style="width: 100%;">
                        <el-table-column width="50">
                            <template slot-scope="scope">
                                <el-radio :label="scope.row.id" v-model="rowSelection"><span></span></el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column label="版本号" width="163" prop="version"></el-table-column>
                    </el-table>
                </el-card>
                <span slot="footer" class="dialog-footer">
                    <el-button type="warning" @click="install">安 装</el-button>
                    <el-button @click="closeDialogInstall">取 消</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>

    export default {

        data() {
            return {
                total: null,
                queryMap: {
                    currentPage: 1,
                    pageSize: 10
                },
                rowSelection:'',
                loading: true,
                installDialogVisible: false,
                ukeyList: [
                    {
                        ukeyId: "3344545",
                        agentId: "agent0001",
                        licenseCreateTime: "2023-03-21 18:38:14",
                        softList: [
                            {
                                softwareId: 2,
                                softwareKey: "11111111111111111111111111111111111111111111111111",
                                softwareName: "淘宝",
                                endTime: "2024-02-01 18:36:44",
                                grantTime: "2023-03-23 14:05:31",
                                licenseCreateTime: "2023-03-21 18:38:14",
                                installed: true
                            }
                        ],
                        locked: false
                    }
                ],
                spanArr: [],
                pos: null,
                tableData: [],
                addDialogVisible: false,
                addForm: {},
                currentIndex: null,
                fqDialoTitle: '',
                softwareList: [],
                softwareVersionList: [],
                historyList:[
                    {
                        grantTime: "2023-2-28 10:00:02",
                        list: [{softwareName: "软件5", endTime: "2023-5-28"},
                            {softwareName: "软件1", endTime: "2023-5-30"}
                        ]
                    }
                ],
                historyTableData:[],
                historySpanArr: [],
                historyPos: null,
            };
        },
        methods: {

            recordClose(){
              this. addDialogVisible=false
              this.historyPos=null
              this.historySpanArr=[]
            },

            rowClickHandle(row) {
                this.rowSelection = row.id  //必须要把唯一值赋给rowSelection,否则会出问题
                this.addForm.versionId = row.id   //radioCheckList就是你选中的当前行数据且只能选一行
            },

            /**
             * id查询软件
             */
            async getSoftwareById(row) {
                this.installDialogVisible = true;
                this.addForm = row
                const {data: res} = await this.$http.get("/factorymng/software/" + row.softwareId);
                this.softwareList = res.data;
                this.softwareVersionList = this.softwareList[0].softwareVersionList
            },


            /**
             * 关闭添加弹出框
             */
            closeDialogInstall() {
                this.installDialogVisible = false
                this.rowSelection=''
            },

            /**
             *履历
             */
           async history(ukeyId){
                this.addForm.UkeyId = ukeyId
                this.fqDialoTitle = this.addForm.UkeyId + "履历"
                this.addDialogVisible = true
                const {data: res} = await this.$http.get("/factorymng/ukey/grant/history/"+ukeyId);
                this.historyList=res.data
                this.historyHandleData(this.historyList)
            },

            /**
             * 鼠标移入(ukey列表)
             **/
            handleCellMouseEnter(row, column, rowIndex, columnIndex) {
                //标记当前是哪个分组
                this.currentIndex = row.ukeyId
            },

            /**
             * 鼠标移入(履历)
             **/
            handleCellMouseEnters(row, column, rowIndex, columnIndex) {
                //标记当前是哪个分组
                this.currentIndex = row.grantTime
            },

            /**
             * 鼠标移出(ukey列表)
             **/
            handleCellMouseLeave() {
                //移出是清空光标
                this.currentIndex = ''
            },

            /**
             * 鼠标移出(履历)
             **/
            handleCellMouseLeaves() {
                //移出是清空光标
                this.currentIndex = ''
            },

            /**
             * 判断格式(ukey列表)
             **/
            tableRowClassName({row, column, rowIndex, columnIndex}) {
                //逻辑判断决定是否修改样式，如果分组一致则使用同一个样式
                if (row.ukeyId === this.currentIndex) {
                    return 'hover-bg'
                } else {
                    return ''
                }
            },

            /**
             * 判断格式(履历)
             **/
            tableRowClassNames({row, column, rowIndex, columnIndex}) {
                //逻辑判断决定是否修改样式，如果分组一致则使用同一个样式
                if (row.grantTime === this.currentIndex) {
                    return 'hover-bg'
                } else {
                    return ''
                }
            },

            /**
             * 合并单元格(uke列表)
             **/
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 8) {

                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },

            /**
             * 合并单元格(履历)
             **/
            objectSpanMethods({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.historySpanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    };
                }
            },

            //返回数据转为一维参数
            handleData(data) {
                this.tableData=[]
                this.spanArr=[]
                this.pos=null
                data.forEach((res, index) => {
                    if (res.softList.length != 0) {
                        for (let i = 0; i < res.softList.length; i++) {
                            res.softwareName = res.softList[i].softwareName
                            res.softwareId = res.softList[i].softwareId
                            res.endTime = res.softList[i].endTime
                            res.grantTime = res.softList[i].grantTime
                            res.installed = res.softList[i].installed
                            res.versionId = res.softList[i].versionId
                            this.tableData.push(JSON.parse(JSON.stringify(res)))
                        }
                    } else {
                        this.tableData.push(res)
                    }
                })
                for (let i = 0; i < this.tableData.length; i++) {
                    if (i == 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (this.tableData[i].ukeyId == this.tableData[i - 1].ukeyId) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },


            historyHandleData(data){
                this.historyTableData=[]
                data.forEach((res, index) => {
                    if (res.list.length != 0) {
                        for (let i = 0; i < res.list.length; i++) {
                            res.softwareName = res.list[i].softwareName
                            res.endTime = res.list[i].endTime
                            this.historyTableData.push(JSON.parse(JSON.stringify(res)))
                        }
                    } else {
                        this.historyTableData.push(res)
                    }
                })

                for (let i = 0; i < this.historyTableData.length; i++) {
                    if (i == 0) {
                        this.historySpanArr.push(1);
                        this.historyPos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (this.historyTableData[i].grantTime === this.historyTableData[i - 1].grantTime) {
                            this.historySpanArr[this.historyPos] += 1;
                            this.historySpanArr.push(0);
                        } else {
                            this.historySpanArr.push(1);
                            this.historyPos = i;
                        }
                    }
                }
            },

            /**
             * 时间格式
             **/
            formatDate(date, fmt) {
                date = new Date(date);
                if (typeof (fmt) === "undefined") {
                    fmt = "yyyy-MM-dd HH:mm:ss";
                }
                if (/(y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
                }
                let o = {
                    'M+': date.getMonth() + 1,
                    'd+': date.getDate(),
                    'H+': date.getHours(),
                    'm+': date.getMinutes(),
                    's+': date.getSeconds()
                }
                for (let k in o) {
                    if (new RegExp(`(${k})`).test(fmt)) {
                        let str = o[k] + ''
                        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
                    }
                }
                return fmt
            },

            /**
             * ukey列表
             */
            async getukeys() {
                const {data: res} = await this.$http.get("/factorymng/ukey/list");
                this.ukeyList = res.data;
                this.handleData(this.ukeyList)
            },

            async lock(ukeyId) {
                const {data: res} = await this.$http.post("/factorymng/ukey/lock/" + ukeyId);
                if (res.success) {
                    this.$notify.success({
                        title: '操作成功',
                        message: '锁定成功',
                    });
                    this.getukeys();
                }
            },

            async unlock(ukeyId) {
                const {data: res} = await this.$http.post("/factorymng/ukey/unlock/" + ukeyId);
                if (res.success) {
                    this.$notify.success({
                        title: '操作成功',
                        message: '解锁成功',
                    });
                    this.getukeys();
                }
            },

            async install() {
                let params = {
                    agentId: this.addForm.agentId,
                    softId: this.addForm.softwareId,
                    versionId: this.addForm.versionId
                }
                const {data: res} = await this.$http.post("/factorymng/agent/install", params);
                if (res.success) {
                    this.$notify.success({
                        title: '操作成功',
                        message: '安装成功',
                    });
                    this.installDialogVisible=false
                }
            }
        }
        ,
        created() {
            this.getukeys()
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    }
    ;
</script>

<style>

    .el-table__body .el-table__row.hover-bg td {
        background-color: #F5F7FA;
    }

</style>
